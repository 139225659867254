import { memo } from 'react';
import { clsx } from 'clsx';
import type { ButtonColors } from '@unifyapps/ui/components/Button';
import { Button } from '@unifyapps/ui/components/Button';
import ModalFooterBase from './Base';

export type ModalFooterHorizontalFullProps = {
  className?: string;
  submitLabel: string;
  cancelLabel: string;
  isPending?: boolean;
  onCancel: () => void;
  onSubmit?: () => void;
  primaryColor?: ButtonColors;
};

function ModalFooterHorizontalFull({
  className,
  submitLabel,
  cancelLabel,
  isPending,
  onCancel,
  onSubmit,
  primaryColor,
}: ModalFooterHorizontalFullProps) {
  return (
    <ModalFooterBase className={clsx('gap-lg flex flex-row', className)}>
      <Button className="flex-1" color="neutral" onClick={onCancel} size="md" variant="outline">
        {cancelLabel}
      </Button>
      <Button
        className="flex-1"
        color={primaryColor ?? 'brand'}
        disabled={isPending}
        loading={isPending}
        onClick={() => onSubmit?.()}
        size="md"
        type="button"
        variant="solid"
      >
        {submitLabel}
      </Button>
    </ModalFooterBase>
  );
}

export default memo(ModalFooterHorizontalFull);
