import { memo } from 'react';
import { clsx } from 'clsx';
import { Typography } from '@unifyapps/ui/components/Typography';
import Stack from '@unifyapps/ui/_components/Stack';
import type { FeaturedIconColors, FeaturedIconSizes } from '@unifyapps/ui/components/FeaturedIcon';
import { FeaturedIcon } from '@unifyapps/ui/components/FeaturedIcon';
import type { IconComponentType } from '@unifyapps/ui/types';
import ModalHeaderBase from './Base';

type ModalHeaderHorizontalProps = {
  title?: string;
  description?: React.ReactNode;
  Icon?: IconComponentType;
  iconUrl?: string;
  className?: string;
  onClose?: () => void;
  color?: FeaturedIconColors;
  size?: FeaturedIconSizes;
  CloseIcon?: IconComponentType;
};

function ModalHeaderHorizontal(props: ModalHeaderHorizontalProps) {
  const { Icon, iconUrl, description, title, className, onClose, color, size, CloseIcon } = props;

  return (
    <ModalHeaderBase
      CloseIcon={CloseIcon}
      className={clsx('gap-xl flex flex-row', className)}
      onClose={onClose}
    >
      {Icon ? <FeaturedIcon Icon={Icon} color={color} size={size ?? 'md'} /> : null}
      {iconUrl ? (
        <FeaturedIcon alt={title ?? ''} color={color} iconUrl={iconUrl} size={size ?? 'md'} />
      ) : null}
      <Stack className="me-3xl flex-col justify-center">
        {title ? (
          <Typography
            color="text-primary"
            maxNumberOfLines={2}
            variant="text-md"
            weight="semi-bold"
          >
            {title}
          </Typography>
        ) : null}
        {description ? (
          <Typography color="text-tertiary" variant="text-sm" weight="regular">
            {description}
          </Typography>
        ) : null}
      </Stack>
    </ModalHeaderBase>
  );
}

export default memo(ModalHeaderHorizontal);
