import type { TFunction } from '@unifyapps/i18n/client';
import { useTranslation } from '@unifyapps/i18n/client';
import type { QueryKey } from '@unifyapps/network/react-query';
import { useQueryClient } from '@unifyapps/network/react-query';
import type { ErrorType } from '@unifyapps/network/utils/fetch';
import { useSnackbar } from '@unifyapps/ui/components/Snackbar';
import type { SnackbarProps } from '@unifyapps/ui/components/Snackbar/types';
import { useCallback } from 'react';
import type { Violation } from '@unifyapps/network/generated/models/violation';
import { UAError } from '@unifyapps/network/utils/consts/error';

export function getResolvedErrorMessage(t: TFunction, error: ErrorType<unknown>) {
  const { payload } = error;

  if (payload) {
    if ('violations' in payload) {
      const violationPayload = payload as { violations?: Violation[] };
      const violationMessage = violationPayload.violations?.[0]?.message;
      if (violationMessage) return violationMessage;
    }

    if (error.payload?.errorCode === UAError.USER_VISIBLE_ERROR && 'message' in payload) {
      return payload.message;
    }
  }

  return t('common:ErrorState.SomethingWentWrong');
}

const useMutationHelpers = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);

  const onShowErrorSnackbar = useCallback(
    (snackbarProps?: Omit<SnackbarProps, 'color'>) => {
      showSnackbar({
        color: 'error',
        title: t('common:ErrorState.SomethingWentWrong'),
        ...snackbarProps,
      });
    },
    [showSnackbar, t],
  );

  const onShowSuccessSnackbar = useCallback(
    (snackbarProps: Omit<SnackbarProps, 'color'>) => {
      showSnackbar({ color: 'success', ...snackbarProps });
    },
    [showSnackbar],
  );

  const onInvalidateQueries = useCallback(
    (queryKeys: QueryKey[]) => {
      queryKeys.forEach((queryKey) => {
        void queryClient.invalidateQueries({ queryKey });
      });
    },
    [queryClient],
  );

  const onInvalidateQueriesAsync = useCallback(
    async (queryKeys: QueryKey[]) => {
      await Promise.all(queryKeys.map((queryKey) => queryClient.invalidateQueries({ queryKey })));
    },
    [queryClient],
  );

  const onMutationError = useCallback(
    (error: ErrorType<unknown>) => {
      onShowErrorSnackbar({ title: getResolvedErrorMessage(t, error) });
    },
    [onShowErrorSnackbar, t],
  );

  return {
    onMutationError,
    onShowSuccessSnackbar,
    onShowErrorSnackbar,
    onInvalidateQueries,
    onInvalidateQueriesAsync,
  };
};

export default useMutationHelpers;
