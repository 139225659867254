import { memo } from 'react';
import { clsx } from 'clsx';
import { Typography } from '@unifyapps/ui/components/Typography';
import Stack from '@unifyapps/ui/_components/Stack';
import { FeaturedIcon } from '@unifyapps/ui/components/FeaturedIcon';
import type { IconComponentType } from '@unifyapps/ui/types';
import ModalHeaderBase from './Base';

type ModalHeaderVerticalProps = {
  title?: string;
  description?: React.ReactNode;
  Icon?: IconComponentType;
  className?: string;
  onClose?: () => void;
};

function ModalHeaderVertical(props: ModalHeaderVerticalProps) {
  const { Icon, description, title, className, onClose } = props;

  return (
    <ModalHeaderBase className={clsx('gap-xl flex flex-col', className)} onClose={onClose}>
      {Icon ? <FeaturedIcon Icon={Icon} size="lg" /> : null}
      <Stack className="gap-xxs">
        {title ? (
          <Typography color="text-primary" variant="text-lg" weight="semi-bold">
            {title}
          </Typography>
        ) : null}
        {description ? (
          <Typography color="text-tertiary" variant="text-sm" weight="regular">
            {description}
          </Typography>
        ) : null}
      </Stack>
    </ModalHeaderBase>
  );
}

export default memo(ModalHeaderVertical);
