import { memo } from 'react';
import { clsx } from 'clsx';
import { Box } from '@unifyapps/ui/components/Box';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import XClose from '@unifyapps/icons/outline/XClose';
import type { IconComponentType } from '@unifyapps/ui/types';

type ModalHeaderBaseProps = {
  children?: React.ReactNode;
  className?: string;
  onClose?: () => void;
  CloseIcon?: IconComponentType;
  iconButtonClassName?: string;
};

function ModalHeaderBase(props: ModalHeaderBaseProps) {
  const { children, className, onClose, CloseIcon, iconButtonClassName } = props;

  return (
    <Box className={clsx('px-xl py-lg border-secondary relative border-b', className)}>
      {children}
      {onClose ? (
        <IconButton
          Icon={CloseIcon ?? XClose}
          className={clsx('absolute end-2 top-2', iconButtonClassName)}
          color="neutral"
          onClick={onClose}
          size="md"
          variant="soft"
        />
      ) : null}
    </Box>
  );
}

export default memo(ModalHeaderBase);
