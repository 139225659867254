import Modal from './Modal';
import ModalHeaderBase from './Headers/Base';
import ModalHeaderHorizontal from './Headers/Horizontal';
import ModalHeaderVertical from './Headers/Vertical';
import ModalHeaderVerticalCenter from './Headers/VerticalCenter';
import ModalContentBase from './Content/Base';
import ModalFooterBase from './Footers/Base';
import ModalFooterHorizontalRight from './Footers/HorizontalRight';
import ModalFooterHorizontalFull from './Footers/HorizontalFull';
import ModalFooterVerticalFull from './Footers/VerticalFull';

const ModalNamespace = Object.assign(Modal, {
  Header: {
    Base: ModalHeaderBase,
    Horizontal: ModalHeaderHorizontal,
    Vertical: ModalHeaderVertical,
    VerticalCenter: ModalHeaderVerticalCenter,
  },
  Content: {
    Base: ModalContentBase,
  },
  Footer: {
    Base: ModalFooterBase,
    HorizontalRight: ModalFooterHorizontalRight,
    HorizontalFull: ModalFooterHorizontalFull,
    VerticalFull: ModalFooterVerticalFull,
  },
});

export default ModalNamespace;
