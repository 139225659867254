import { cva } from 'class-variance-authority';
import type { LinkColors, LinkSizes } from './types';

export const linkDecoratorVariants = cva('', {
  variants: {
    size: {
      xs: 'size-3',
      sm: 'size-4',
      md: 'size-4',
      lg: 'size-5',
    } satisfies Record<LinkSizes, string>,
    color: {
      brand: 'focus-visible:ring-brand',
      neutral: 'focus-visible:ring-gray',
      error: 'focus-visible:ring-error',
    } satisfies Record<LinkColors, string>,
  },
});

export const linkVariants = cva('decoration-current', {
  variants: {
    size: {
      xs: 'gap-xxs text-xs',
      sm: 'gap-xs text-sm',
      md: 'gap-xs text-sm',
      lg: 'gap-sm text-md',
    } satisfies Record<LinkSizes, string>,
    underline: {
      //https://github.com/tailwindlabs/tailwindcss/blob/next/packages/tailwindcss/preflight.css#L95 causing decoration to not apply
      always: '!underline',
      hover: '',
      none: '',
    },
  },
});
