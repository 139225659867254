import { memo } from 'react';
import { clsx } from 'clsx';
import { Button } from '@unifyapps/ui/components/Button';
import ModalFooterBase from './Base';

export type ModalFooterVerticalFullProps = {
  className?: string;
  submitLabel: string;
  cancelLabel: string;
  isPending?: boolean;
  onCancel: () => void;
  onSubmit?: () => void;
};

function ModalFooterVerticalFull({
  className,
  submitLabel,
  cancelLabel,
  isPending,
  onCancel,
  onSubmit,
}: ModalFooterVerticalFullProps) {
  return (
    <ModalFooterBase className={clsx('gap-lg flex flex-col items-stretch', className)}>
      <Button
        className="flex-1"
        color="brand"
        disabled={isPending}
        loading={isPending}
        onClick={() => onSubmit?.()}
        size="md"
        type="button"
        variant="solid"
      >
        {submitLabel}
      </Button>
      <Button className="flex-1" color="neutral" onClick={onCancel} size="md" variant="outline">
        {cancelLabel}
      </Button>
    </ModalFooterBase>
  );
}

export default memo(ModalFooterVerticalFull);
