export enum PageVariableType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Object = 'object',
  Array = 'array',
}

export interface BasePageVariableType {
  id: string;
  createdTime: number;
  name: string;
  initialValue?: string;
}

export interface StringTypePageVariable extends BasePageVariableType {
  type: PageVariableType.String;
}

export interface NumberTypePageVariable extends BasePageVariableType {
  type: PageVariableType.Number;
}

export interface BooleanTypePageVariable extends BasePageVariableType {
  type: PageVariableType.Boolean;
}

export interface ObjectTypePageVariable extends BasePageVariableType {
  type: PageVariableType.Object;
}

export interface ArrayTypePageVariable extends BasePageVariableType {
  type: PageVariableType.Array;
}

export type PageVariable =
  | StringTypePageVariable
  | NumberTypePageVariable
  | BooleanTypePageVariable
  | ObjectTypePageVariable
  | ArrayTypePageVariable;

export type PageVariableState =
  | {
      id: string;
      name: string;
      value: unknown;
    }
  | undefined;

export type ComputedPageVariableState<T> = {
  id: string;
  name: string;
  value: T;
};

interface BaseVariableTypePayload {
  variableId: string;
  method: 'setPageVariable';
}

export interface SetVariableArrayTypePayload extends BaseVariableTypePayload {
  operationDetails: {
    operation: 'SET' | 'APPEND' | 'PREPEND' | 'POP' | 'SHIFT';
    value?: string;
  };
}

export interface SetVariableStringTypePayload extends BaseVariableTypePayload {
  operationDetails: {
    operation: 'SET' | 'APPEND' | 'PREPEND';
    value?: string;
  };
}

export interface SetVariableNumberTypePayload extends BaseVariableTypePayload {
  operationDetails: {
    operation: 'SET' | 'INCREMENT_BY' | 'DECREMENT_BY';
    value?: string;
  };
}

export interface SetVariableBooleanTypePayload extends BaseVariableTypePayload {
  operationDetails: {
    operation: 'SET' | 'TOGGLE';
    value?: string;
  };
}

export interface SetVariableObjectTypePayload extends BaseVariableTypePayload {
  operationDetails: {
    operation: 'SET' | 'MERGE';
    value?: string;
  };
}

export type SetVariablePayloadType =
  | SetVariableArrayTypePayload
  | SetVariableStringTypePayload
  | SetVariableNumberTypePayload
  | SetVariableBooleanTypePayload
  | SetVariableObjectTypePayload;
