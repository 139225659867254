export const enum UAResources {
  StorageByUAFetchRecords = 'storage_by_unifyapps_fetch_records',
  AnalyticsByUAQueryReports = 'analytics_by_unifyapps_query_reports',
  StandardEntitiesFetchEntities = 'standard_entities_fetch_entities',
  AnalyticsByUAAggregateMetadata = 'analytics_by_unifyapps_aggregate_metadata',
  StorageByUAAggregateMetadata = 'storage_by_unifyapps_aggregate_metadata',
  CommentsByUAFindComments = 'comments_by_unifyapps_find_comments',
  CommentsByUAAddComment = 'comments_by_unifyapps_add_comment',
  CommentsByUAAddReply = 'comments_by_unifyapps_add_reply',
  CommentsByUADeleteComment = 'comments_by_unifyapps_delete_comment',
  CommentsByUAFindReplies = 'comments_by_unifyapps_find_replies',
  CallablesCallAutomation = 'callables_call_automation',
  UserAudit = 'standard_entities_fetch_user_audit',
  StandardEntitiesAudit = 'standard_entities_fetch_audit',
  StandardEntitiesResetPassword = 'standard_entities_reset_password',
}
