import { useQueryClient } from '@unifyapps/network/react-query';
import DataSourceHelper from '../../../../helper/DataSourceHelper';
import { useDataSourceRecordStore } from '../../../../stores/DataSourceRecordStore';
import { REQUEST_ID_PREFIX } from '../../../../const';
import { getIdWithPrefix } from '../../../../../utils/id';
import type {
  ActionHandlerType,
  OnActionArgs,
} from '../../../../components/ActionsProvider/context';
import {
  useGetGlobalStateStoreState,
  useGlobalStateStore,
} from '../../../../stores/GlobalStateStore';
import useGetAutomaticDataSourceIds from '../../../../components/DataSourceController/hooks/useGetAutomaticDataSourceIds';

const useControlDataSource = () => {
  const {
    datasource: { triggerManualDataSource: triggerManualDataSourceAction },
  } = useGlobalStateStore().use.actions();
  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const { automaticDataSourceIds } = useGetAutomaticDataSourceIds();
  const dataSources = useDataSourceRecordStore().use.dataSources();
  const queryClient = useQueryClient();

  const onControlDataSource: ActionHandlerType = ({ action }: OnActionArgs) => {
    const method = action.payload?.method as string | undefined;

    switch (method) {
      case 'trigger': {
        const dataSourceId = action.payload?.dataSourceId as string | undefined;
        // returning promise to wait for dataSource response
        // If there is no dataSourceId, return a resolved promise
        if (!dataSourceId) return Promise.resolve();

        const dataSource = getGlobalStateStoreState().dataSources[dataSourceId];
        const dataSourceRecord = dataSources[dataSourceId];

        const queryKeyPrefix = action.payload?.queryKeyPrefix as string | undefined;

        // get the identity of the dataSource
        const isAutomaticDataSourceLegacy = automaticDataSourceIds.includes(dataSourceId);
        const isAutomaticDataSource = DataSourceHelper.isAutomaticDataSource(dataSourceRecord);
        const isManualDataSource = DataSourceHelper.isManualDataSource(dataSourceRecord);

        // in case of automatic data source, we only invalidate the query because
        // we expect the automatic data source to have been called via dependency flow manager
        const triggerAutomaticDataSource = () => {
          if (dataSource?.queryKey) {
            return queryClient.invalidateQueries({
              queryKey: dataSource.queryKey,
            });
          }
          return Promise.resolve();
        };

        const triggerManualDataSource = () => {
          return new Promise<void>((resolve) => {
            const key = getIdWithPrefix(REQUEST_ID_PREFIX);
            triggerManualDataSourceAction(key, {
              dataSourceId,
              queryKeyPrefix,
              callback: () => resolve(),
            });
          });
        };

        // decide which trigger to call based on the dataSource type
        // ORDER is very IMPORTANT, because we want to act on new type of data source first before handling legacy
        if (isAutomaticDataSource && dataSource?.queryKey) {
          return triggerAutomaticDataSource();
        } else if (isManualDataSource) {
          return triggerManualDataSource();
        } else if (isAutomaticDataSourceLegacy && dataSource?.queryKey) {
          return triggerAutomaticDataSource();
        } else if (!isAutomaticDataSourceLegacy || queryKeyPrefix) {
          return triggerManualDataSource();
        }

        return Promise.resolve();
      }

      default:
        return Promise.resolve();
    }
  };

  return { onControlDataSource };
};

export default useControlDataSource;
