import type { InterfacePageType } from '@unifyapps/defs/types/page';
import type { LookupRequest } from '@unifyapps/network/generated/models/lookupRequest';

export const getLookupRequestsForDataSources = (
  dependencies: NonNullable<InterfacePageType['metadata']>['dependencies'],
  dependencyById: NonNullable<InterfacePageType['metadata']>['dependencyById'],
) => {
  const lookupRequestsMap = new Map<string, string[]>();
  const lookupRequests: Partial<Record<string, LookupRequest[]>> = {};

  if (!dependencies || !dependencyById) {
    return lookupRequests;
  }

  Object.entries(dependencies).forEach(([key, value]) => {
    const keyDependency = dependencyById[key];
    const valueDependency = value ? dependencyById[value] : undefined;

    if (keyDependency?.type === 'DATA_SOURCE' && valueDependency?.type === 'LOOKUP_REQUEST') {
      if (!lookupRequests[keyDependency.dataSourceId]) {
        lookupRequests[keyDependency.dataSourceId] = [];
        lookupRequestsMap.set(keyDependency.dataSourceId, []);
      }

      const lookupRequestNames = lookupRequestsMap.get(keyDependency.dataSourceId) || [];
      const name = valueDependency.lookupRequest.name || '';

      if (lookupRequestNames.includes(name)) {
        return;
      }

      lookupRequestsMap.set(keyDependency.dataSourceId, [...lookupRequestNames, name]);
      lookupRequests[keyDependency.dataSourceId]?.push(valueDependency.lookupRequest);
    }
  });

  return lookupRequests;
};
