import { memo } from 'react';
import { clsx } from 'clsx';
import { Box } from '@unifyapps/ui/components/Box';

function ModalFooterBase(props: { children?: React.ReactNode; className?: string }) {
  const { children, className } = props;

  return <Box className={clsx('p-xl border-secondary border-t', className)}>{children}</Box>;
}

export default memo(ModalFooterBase);
