import type { Filter } from '@unifyapps/network/generated/models/filter';
import { Op } from '@unifyapps/network/generated/models/op';
import { InterfacePageEntityComponentType } from '@unifyapps/defs/types/page';
import type { InterfaceComponent } from '../types/interfaceComponent';
import { EMPTY_ARRAY } from '../../consts/empty';

export const PAGE_COMPONENT_FILTER = [
  {
    field: 'properties.componentType',
    op: Op.EQUAL,
    values: ['PAGE'],
  },
];

export const MODULE_COMPONENT_FILTER = [
  {
    field: 'properties.componentType',
    op: Op.EQUAL,
    values: ['MODULE'],
  },
];

export const getLookupInterfaceQueryFilter = ({
  interfaceId,
  interfaceComponent,
  searchFilters = [],
}: {
  interfaceId: string;
  interfaceComponent?: InterfaceComponent;
  searchFilters?: Filter[];
}) => {
  let interfaceComponentFilter = EMPTY_ARRAY as Filter[];

  if (interfaceComponent) {
    interfaceComponentFilter =
      interfaceComponent.type === InterfacePageEntityComponentType.MODULE
        ? [
            {
              field: 'id',
              op: Op.EQUAL,
              values: [interfaceComponent.id],
            },
          ]
        : [
            {
              op: Op.OR,
              values: [
                {
                  field: 'properties.slug',
                  op: Op.EQUAL,
                  values: [interfaceComponent.slug],
                },
                {
                  // todo: backward compatibility
                  // this filter is for backward compatibility, when slug is not stored in properties of interface component
                  // When every page component has slug in it, then this OR filter won't be needed
                  field: 'id',
                  op: Op.EQUAL,
                  values: [interfaceComponent.slug],
                },
              ],
            },
          ];
  }

  return {
    filter: {
      op: Op.AND,
      values: [
        {
          op: Op.OR,
          values: [
            { field: 'properties.interfaceId', op: Op.EQUAL, values: [interfaceId] },

            // todo: backward compatibility -- parentComponent was used before. interfaceId added recently
            { field: 'properties.parentComponent', op: Op.EQUAL, values: [interfaceId] },
          ],
        },
        ...interfaceComponentFilter,
        ...searchFilters,
      ],
    },
    sorts: [
      {
        field: 'properties.name',
        order: 'ASC' as const,
      },
    ],
    page: {
      limit: 100,
      offset: 0,
    },
  };
};
