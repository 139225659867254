import { useGlobalStateStore } from '../../../stores/GlobalStateStore';
import DependencyFlowHelper from '../../../stores/GlobalStateStore/helpers/DependencyFlowHelper';

function useGetAutomaticDataSourceIds() {
  const dsDependencyFlow = useGlobalStateStore().use.dependencyFlow.dataSources();

  // intention is to get all the committed and finished data source
  const nonStagedDatasources = Object.keys(dsDependencyFlow).filter(
    (key) => !DependencyFlowHelper.isDependencyStaging(dsDependencyFlow, key),
  );

  return {
    automaticDataSourceIds: nonStagedDatasources,
  };
}

export default useGetAutomaticDataSourceIds;
