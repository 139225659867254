import { useContext } from 'react';
import { SnackbarContext } from './context';

const useSnackbar = () => {
  const contextValue = useContext(SnackbarContext);

  if (!contextValue) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }

  return contextValue;
};

export default useSnackbar;
