import type { Action } from '../action';

export enum EventTypeEnum {
  OnClick = 'onClick',
  OnSubmit = 'onSubmit',
  OnChange = 'onChange',
  OnOpen = 'onOpen',
  OnSave = 'onSave',
  OnRowSelect = 'onRowSelect',
  OnClickCell = 'onClickCell',
  OnClose = 'onClose',
  OnCollapse = 'onCollapse',
  OnExpand = 'onExpand',
  OnDataPointClick = 'onDataPointClick',
  OnFileUpload = 'onFileUpload',
  OnSuccess = 'onSuccess',
  OnFailure = 'onFailure',
  OnFocus = 'onFocus',
  OnBlur = 'onBlur',
  OnPhotoCapture = 'onPhotoCapture',
  OnRecordingStart = 'onRecordingStart',
  OnRecordingStop = 'onRecordingStop',
  OnRecordedFileCapture = 'onRecordedFileCapture',
  OnStepChange = 'onStepChange',
  OnMenuItemClick = 'OnMenuItemClick',
  OnReceiveNotification = 'onReceiveNotification',
  OnSendMessage = 'onSendMessage',
  OnOrderChange = 'onOrderChange',
  OnTimerExpire = 'onTimerExpire',
  OnTimerIntervalElapsed = 'onTimerIntervalElapsed',
  OnMarkerClick = 'onMarkerClick',
  OnPermissionDenied = 'onPermissionDenied',
  OnCurrentTimeChange = 'onCurrentTimeChange',
  OnSelectItem = 'onSelectItem',
}

export interface Event {
  /**
   * unique identifier for the event
   */
  id: string;
  /**
   * The event that triggers the action. This should correspond to a predefined set of events in the application.
   */
  eventType: string;

  action: Action;
  /**
   * The targetId by which the event will be triggered.
   */
  targetId?: string;
  /**
   * Event is hidden and is not shown in the UI
   */
  hidden?: boolean;
  /**
   * Event event type payload data
   */
  payload?: Record<string, unknown>;
}
