import DataSourceHelper from '../helper/DataSourceHelper';
import type { UnsavedDataSource } from '../stores/DataSourceRecordStore';

function getPayloadForDataSourceOutputSchema(dataSource: UnsavedDataSource) {
  return {
    context: DataSourceHelper.context(dataSource) as Record<string, unknown>,
    inputs: {
      ...DataSourceHelper.inputs(dataSource),
      synchronous: true,
    },
  };
}

export default getPayloadForDataSourceOutputSchema;
