import './link.css';
import { default as BaseLink } from '@mui/joy/Link';
import { forwardRef } from 'react';
import { clsx } from 'clsx';
import type { LinkProps } from './types';
import { linkVariants, linkDecoratorVariants } from './styles';

const LINK_ID_CLASSNAME = 'ua-link';

const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref): JSX.Element => {
  const {
    size = 'md',
    color,
    startDecoratorComponent: StartDecoratorComponent,
    startDecoratorNode,
    endDecoratorComponent: EndDecoratorComponent,
    endDecoratorNode,
    className,
    decoratorClassName,
    underline,
    ...rest
  } = props;

  const linkClassName = linkVariants({
    className: [LINK_ID_CLASSNAME, className],
    size,
    underline,
  });

  const linkDecoratorClassName = linkDecoratorVariants({
    size,
  });

  const startDecorator = StartDecoratorComponent ? (
    <StartDecoratorComponent className={clsx(linkDecoratorClassName, decoratorClassName)} />
  ) : (
    startDecoratorNode
  );

  const endDecorator = EndDecoratorComponent ? (
    <EndDecoratorComponent className={clsx(linkDecoratorClassName, decoratorClassName)} />
  ) : (
    endDecoratorNode
  );

  return (
    <BaseLink
      ref={ref}
      {...rest}
      className={linkClassName}
      data-color={color}
      data-size={size}
      endDecorator={endDecorator}
      startDecorator={startDecorator}
      underline={underline}
    />
  );
});

Link.displayName = 'Link';

export default Link;
