import { memo } from 'react';
import { clsx } from 'clsx';
import type { ButtonColors } from '@unifyapps/ui/components/Button';
import { Button } from '@unifyapps/ui/components/Button';
import ModalFooterBase from './Base';

export type ModalFooterHorizontalRightProps = {
  className?: string;
  startDecoratorNode?: React.ReactNode;
  submitLabel: string;
  cancelLabel?: string;
  disabled?: boolean;
  isPending?: boolean;
  hidePrimaryAction?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  primaryColor?: ButtonColors;
};

function ModalFooterHorizontalRight({
  className,
  startDecoratorNode,
  submitLabel,
  cancelLabel,
  disabled,
  isPending,
  onCancel,
  onSubmit,
  hidePrimaryAction,
  primaryColor,
}: ModalFooterHorizontalRightProps) {
  return (
    <ModalFooterBase className={clsx('gap-lg flex flex-row justify-end', className)}>
      {startDecoratorNode}
      {cancelLabel && onCancel ? (
        <Button color="neutral" onClick={onCancel} size="md" variant="outline">
          {cancelLabel}
        </Button>
      ) : null}
      {!hidePrimaryAction ? (
        <Button
          color={primaryColor ?? 'brand'}
          disabled={disabled || isPending}
          loading={isPending}
          onClick={() => onSubmit?.()}
          size="md"
          type="button"
          variant="solid"
        >
          {submitLabel}
        </Button>
      ) : null}
    </ModalFooterBase>
  );
}

export default memo(ModalFooterHorizontalRight);
