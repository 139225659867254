import { memo } from 'react';
import { clsx } from 'clsx';
import { Box } from '@unifyapps/ui/components/Box';

type ModalContentBaseProps = {
  children?: React.ReactNode;
  className?: string;
};

function ModalContentBase(props: ModalContentBaseProps) {
  const { children, className } = props;

  return (
    <Box className={clsx('p-xl flex flex-1 flex-col overflow-auto', className)}>{children}</Box>
  );
}

export default memo(ModalContentBase);
