import { useMemo } from 'react';
import type { UnsavedDataSource } from '../../../stores/DataSourceRecordStore';
import {
  useDataSourceRecords,
  useUnsavedDataSourceRecords,
} from '../../../stores/DataSourceRecordStore';

function useMergedDataSources() {
  const storeDataSources = useDataSourceRecords();
  const unsavedDataSources = useUnsavedDataSourceRecords();

  const adaptedDataSources = useMemo(() => {
    const allDataSourcesKeys = Object.keys(storeDataSources);
    return allDataSourcesKeys.reduce<Record<string, UnsavedDataSource | undefined>>((acc, id) => {
      acc[id] = unsavedDataSources[id] || storeDataSources[id];
      return acc;
    }, {});
  }, [storeDataSources, unsavedDataSources]);

  return { dataSources: adaptedDataSources };
}

export default useMergedDataSources;
