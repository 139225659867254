import type { BlockType } from '@unifyapps/defs/types/block';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import DependencyFlowHelper from '../../../stores/GlobalStateStore/helpers/DependencyFlowHelper';
import { useDataSourceRecordStore } from '../../../stores/DataSourceRecordStore';
import DataSourceHelper from '../../../helper/DataSourceHelper';
import { useGlobalStateStore } from '../../../stores/GlobalStateStore';

export function useTriggerAutomaticDataSources({
  dataSourceIds = [],
  nonBlockingDataSourceIds,
}: {
  nonBlockingDataSourceIds: BlockType['nonBlockingDataSourceIds'];
  dataSourceIds: BlockType['dataSourceIds'];
}) {
  const dsDependencyFlow = useGlobalStateStore().use.dependencyFlow.dataSources();
  const dataSources = useGlobalStateStore().use.dataSources();
  const dataSourceRecords = useDataSourceRecordStore().use.dataSources();
  const { datasource } = useGlobalStateStore().use.actions();

  const automaticDataSourceIds = useMemo(() => {
    // it is possible that we might have includes manual data source id in dataSourceIds
    return dataSourceIds.filter((id) => {
      return !DataSourceHelper.isManualDataSource(dataSourceRecords[id]);
    });
  }, [dataSourceIds, dataSourceRecords]);

  const nonStagedDataSourceIds = useMemo(() => {
    return automaticDataSourceIds.filter((id) => {
      return DependencyFlowHelper.isDependencyUnstaged(dsDependencyFlow, id);
    });
  }, [automaticDataSourceIds, dsDependencyFlow]);

  useEffect(() => {
    if (nonStagedDataSourceIds.length) {
      datasource.addDependencyFlows(
        DependencyFlowHelper.createStagingDependencyFlow({
          ids: nonStagedDataSourceIds,
        }),
      );
    }
  }, [datasource, nonStagedDataSourceIds]);

  const areAllQueriesReady = automaticDataSourceIds.every((id) => {
    return DependencyFlowHelper.isDependencyFinished(dsDependencyFlow, id);
  });

  const isWaitingForRequestsToBeMade = automaticDataSourceIds.some((id) => {
    return isEmpty(dataSources[id]);
  });

  const isLoadingBlockingQueries = automaticDataSourceIds.some((id) => {
    const value = dataSources[id];
    return value?.isLoading !== false && !nonBlockingDataSourceIds?.includes(id);
  });

  return {
    isSuspended: isLoadingBlockingQueries || isWaitingForRequestsToBeMade || !areAllQueriesReady,
  };
}
